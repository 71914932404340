import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers: (ctx, q) => axiosIns.get(`/api.user${q || ''}`),
    fetchPublicUsers: (ctx, q) => axiosIns.get(`/api.user/public${q || ''}`),
    fetchUser: (ctx, { id }) => axiosIns.get(`/api.user/${id}`),
    createUser: (ctx, data) => axiosIns.post('/api.user', data),
    updateUser: (ctx, data) => axiosIns.put(`/api.user/${data.id}`, data),
    changePassword: (ctx, data) => axiosIns.post('/api.user/change-password', data),
    adminChangePassword: (ctx, data) => axiosIns.post('/api.user/admin-change-password', data),
    deleteUser: (ctx, { id }) => axiosIns.delete(`/api.user/${id}`),
  },
}
